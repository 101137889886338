import {Location} from 'domain';
const Parse = global.Parse;

const LOCATION_FIELDS = Object.keys(Location.DEFAULTS);

/**
 * Fetches a Location's details
 *
 * @param {string} locationId - The unique identifier of the location.
 * @returns {Promise<Object>} - A promise that resolves to the location details.
 */
const get = (locationId) => {
    const query = new Parse.Query(Location)
        .select(LOCATION_FIELDS);

    return {
        type: 'GET_LOCATION',
        meta: { locationId },
        payload: query.get(locationId),
    };
};

/**
 * Set the current location to this Location without fetching it from the DB
 *
 * @param {Object} location - The location to set as the currently viewed location.
 * @returns {Promise<Object>} - A promise that resolves to the location details.
 */
const set = (location) => {
    return {
        type: 'SET_LOCATION',
        meta: { location },
        payload: Promise.resolve(location),
    };
};

/**
 * Fetches a list of all Locations.
 *
 * @returns {Promise<Object>} - A promise that resolves to the location details.
 */
const list = () => {
    const query = new Parse.Query(Location)
        .equalTo('active', true)
        .ascending('name')
        .limit(1000)
        .select(LOCATION_FIELDS);

    return {
        type: 'LIST_LOCATION',
        meta: {},
        payload: query.find(),
    };
};

/**
 * Saves changes to a location object
 *
 * @returns {Promise<Object>} - A promise that resolves to the location details.
 */
const save = (location) => {
    return {
        type: 'SAVE_LOCATION',
        meta: { location },
        payload: location.save(),
    };
};

/**
 * Signals to reducer to clear the location data.
 */
const clear = () => ({
    type: `CLEAR_LOCATION`,
    meta: {},
    payload: Promise.resolve(null)
});

export default {
    get,
    set,
    list,
    save,
    clear,
};
