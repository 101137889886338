import React from 'react';
import {useLocation, Link as RouterLink, useParams} from 'react-router-dom';
import { Breadcrumbs, Typography, Box, Link } from '@mui/joy';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import {useSelector} from "react-redux";
import CommonUtils from "utils/CommonUtils";

const Breadcrumb = () => {
    const urlLocation = useLocation();
    const { locationId, employeeId, eventId, activityId } = useParams();
    const pathnames = urlLocation.pathname.split('/').filter(x => x);

    const me = useSelector(({ auth }) => auth.me);
    const isAuthorized = useSelector(({ auth }) => auth.isAuthorized);
    const employee = useSelector(({ employees }) => employees.current);
    const activity = useSelector(({ activity }) => activity.current);
    const location = useSelector(({ locations }) => locations.current);
    const event = useSelector(({ event }) => event.current);

    if(!me?.id || !isAuthorized) {
        return null;
    }

    const crumbs = [
        {id: 'dashboard', urlPart: '', label: "Dashboard", enabled: pathnames.includes('dashboard')},
        {id: 'events', urlPart: 'event', label: "Events", enabled: pathnames.includes('event')},
        {id: 'event', urlPart: event?.id, label:  CommonUtils.formatDateRange(event?.start, event?.end), enabled: event?.id && !eventId},
        {id: 'activities', urlPart: 'activity', label: "Activities", enabled: pathnames.includes('activity')},
        {id: 'activity', urlPart: activity?.id, label:  activity?.location?.name, enabled: activity?.id && !activityId},
        {id: 'locations', urlPart: 'location', label: "Locations", enabled: pathnames.includes('location')},
        {id: 'location', urlPart: location?.id, label:  location?.name, enabled: location?.id && !locationId},
        {id: 'employees', urlPart: 'employee', label: "Employees", enabled: pathnames.includes('employee')},
        {id: 'employee', urlPart: employee?.id, label:  employee?.name, enabled: employee?.id && !employeeId},
    ].filter(bc => bc.enabled)

    const crumbParts = crumbs.map(crumb => crumb.urlPart)

    if(crumbParts.length === 0) {
        return null;
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }} px={2}>
            <Breadcrumbs
                size="sm"
                aria-label="breadcrumbs"
                separator={<ChevronRightRoundedIcon fontSize="sm" />}
                sx={{ pl: 0 }}
            >
                <Link component={RouterLink} to="/" sx={{ display: 'flex', alignItems: 'center' }}>
                    <HomeRoundedIcon />
                </Link>
                {crumbs.map((crumb, index) => {
                    const last = index === crumbs.length - 1;
                    const to = `/${crumbParts.slice(0, index + 1).join('/')}`;

                    return last ? (
                        <Typography
                            key={crumb.id}
                            color="primary"
                            fontWeight={500}
                            fontSize={12}
                            sx={{
                                maxWidth: `${50/crumbs.length}vw`,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {crumb.label}
                        </Typography>
                    ) : (
                        <Link
                            key={crumb.id}
                            component={RouterLink}
                            to={to}
                            underline="hover"
                            color="neutral"
                            fontSize={12}
                            fontWeight={500}
                            sx={{
                                maxWidth: `${50/crumbs.length}vw`,
                                display: 'inline-block',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {crumb.label}
                        </Link>
                    );
                })}
            </Breadcrumbs>
        </Box>
    );
};

export default Breadcrumb;
