const Parse = global.Parse;
const EventLocation = Parse.Object.extend("EventLocation");

const EVENT_LOCATION_FIELDS = ['event', 'location', 'status']

const get = (id) => ({
    type: 'GET_EVENT_LOCATION',
    meta: { id },
    payload: new Parse.Query(EventLocation)
        .select(EVENT_LOCATION_FIELDS)
        .get(id),
});

const getByEvent = (event) => ({
    type: 'GET_EVENT_LOCATION_BY_EVENT',
    meta: { event },
    payload: new Parse.Query(EventLocation)
        .equalTo('event', event)
        .include('location')
        .select(EVENT_LOCATION_FIELDS)
        .find(),
});

const setAll = (eventLocations) => {
    return {
        type: 'SET_ALL_EVENT_LOCATION',
        meta: { eventLocations },
        payload: Promise.resolve(eventLocations),
    };
};

const saveAll = (events) => ({
    type: 'SAVE_ALL_EVENT_LOCATION',
    meta: { events },
    payload: Parse.Object.saveAll(events),
});

const clear = () => ({
    type: 'CLEAR_EVENT_LOCATION',
    meta: {},
    payload: Promise.resolve(null)
});


export default {
    get,
    getByEvent,
    saveAll,
    setAll,
    clear,
};
