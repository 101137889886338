const Parse = global.Parse;

/**
 * Attempts to log in a user.
 *
 * @param {string} username - The username of the user.
 * @param {string} password - The password of the user.
 * @returns {Promise<Object>} - A promise that resolves to the user details.
 */
const login = (username, password) => {
    return {
        type: `LOGIN_USER`,
        payload: Parse.User.logIn(username, password),
    };
};

/**
 * Logs out the current user.
 *
 * @returns {Promise} - A promise that resolves when the user is logged out.
 */
const logout = () => {
    return {
        type: `LOGOUT_USER`,
        payload: Parse.User.logOut(),
    };
};

/**
 * Send a reset password email to user.
 *
 * @returns {Promise} - A promise that resolves when the user is logged out.
 */
const reset = (email) => {
    return {
        type: 'RESET_USER',
        meta: { email },
        payload: Parse.User.requestPasswordReset(email),
    };
};

/**
 * Fetches the current authenticated user's details.
 *
 * @returns {Object|null} - The current user's details or null if not logged in.
 */
const me = () => {
    return {
        type: `RESTORE_USER`,
        payload: Parse.User.current(),
    };
};

export default {
    login,
    logout,
    reset,
    me,
};
