const Parse = global.Parse;
const Event = Parse.Object.extend("Event");

import WatcherUtils from 'utils/WatcherUtils';

const EVENT_FIELDS = ['description', 'start', 'end']

const get = (id) => ({
    type: 'GET_EVENT',
    meta: { eventId: id },
    payload: new Parse.Query(Event)
        .select(EVENT_FIELDS)
        .get(id),
});

const watch = (id, functions) => ({
    type: 'WATCH_EVENT',
    meta: { eventId: id },
    payload: WatcherUtils.watch(
        'event',
        id,
        functions,
        new Parse.Query(Event)
            .equalTo('objectId', id)
            .select(EVENT_FIELDS)
    ),
});

const unwatch = (id) => {
    return {
        type: 'UNWATCH_EVENT',
        meta: { eventId: id },
        payload: WatcherUtils.unwatch('event', id),
    };
};

const set = (event) => ({
    type: 'SET_EVENT',
    meta: { event },
    payload: Promise.resolve(event),
});

const list = () => ({
    type: 'LIST_EVENT',
    meta: {},
    payload: new Parse.Query(Event)
        .descending('start')
        .select(EVENT_FIELDS)
        .limit(1000)
        .find(),
});

const add = (locations) => ({
    type: 'ADD_EVENT',
    meta: { locations },
    payload: Parse.Cloud.run('event-deploy', { locations }),
});

const save = (event) => ({
    type: 'SAVE_EVENT',
    meta: { event },
    payload: event.save(),
});

const clear = () => ({
    type: 'CLEAR_EVENT',
    meta: {},
    payload: Promise.resolve(null)
});

const getActive = () => ({
    type: 'GET_ACTIVE_EVENT',
    meta: {},
    payload: new Parse.Query(Event)
        .doesNotExist('end')
        .select(EVENT_FIELDS)
        .first(),
});

const watchActive = (functions) => ({
    type: 'WATCH_ACTIVE_EVENT',
    meta: {},
    payload: WatcherUtils.watch(
        'event',
        'active',
        functions,
        new Parse.Query('Event')
            .doesNotExist('end')
            .select(EVENT_FIELDS)
    ),
});

const unwatchActive = () => {
    return {
        type: 'UNWATCH_ACTIVE_EVENT',
        meta: {},
        payload: WatcherUtils.unwatch('event', 'active'),
    };
};

const setActive = (event) => ({
    type: 'SET_ACTIVE_EVENT',
    meta: { event },
    payload: Promise.resolve(event),
});

export default {
    get,
    watch,
    unwatch,
    set,
    list,
    add,
    save,
    clear,
    getActive,
    watchActive,
    unwatchActive,
    setActive,
};
