import React, { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { useTheme } from '@mui/joy/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Avatar,
    Box,
    Chip,
    Divider,
    Typography,
    Sheet,
    IconButton,
    List,
    ListItem,
    ListItemContent,
    GlobalStyles,
    Card, Stack, Button
} from '@mui/joy';

import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';

import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ActivityIcon from "@mui/icons-material/Newspaper";
import LocationIcon from "@mui/icons-material/LocationOn";
import DispatchIcon from "@mui/icons-material/DynamicForm";
import RocketLaunch from "@mui/icons-material/RocketLaunch";
import MenuIcon from '@mui/icons-material/MenuOpen';

import OpenActiveButton from "components/event/OpenActiveButton";
import authActions from "actions/authActions";
import {Employee} from "domain";

export default function Sidebar() {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isOpen, setIsOpen] = React.useState(!isSmScreen);
    const account = useSelector(({ account }) => account.me);
    const activeEvent = useSelector(({ event }) => event.active);
    const profile = useSelector(({ employees }) => employees.me);

    const isPathActive = (path) => {
        return location.pathname.startsWith(path);
    };

    const go = (path) => () => {
        navigate(path);
    };

    const handleLogout = () => {
        dispatch(authActions.logout());
    };

    const isEventOpen = activeEvent && isPathActive(`/event/${activeEvent.id}`);

    useEffect(() => {
        setIsOpen(!isSmScreen);
    }, [isSmScreen]);

    if(!profile) {
        return null;
    }

    return (
        <>
            <IconButton
                onClick={() => setIsOpen(!isOpen)}
                variant={isOpen ? 'soft' : 'solid'}
                size={isOpen ? 'sm' : 'lg'}
                color='neutral'
                sx={{
                    position: 'fixed',
                    top: 16,
                    left: isOpen ? 'calc(var(--Sidebar-width) - 48px)' : -24,
                    transition: 'all 0.6s',
                    zIndex: 1300,
                    '&:hover': {
                        left: isOpen ? 'calc(var(--Sidebar-width) - 48px)' : -6,
                    },
                    '& .MuiSvgIcon-root': {
                        transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                        transition: 'transform 0.8s',
                    }
                }}
            >
                <MenuIcon />
            </IconButton>
            {isOpen && isSmScreen && (
                <Box
                    sx={{
                        position: 'fixed',
                        zIndex: 'var(--joy-zIndex-popup)',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'var(--joy-palette-background-backdrop)',
                        transition: 'opacity 0.6s',
                        opacity: { xs: isOpen ? 1 : 0, md: 0 },
                    }}
                    onClick={() => setIsOpen(false)}
                />
            )}
            <Sheet
                className="Sidebar"
                sx={{
                    position: 'sticky',
                    transform: isOpen ? 'translateX(0)' : 'translateX(-95%)',
                    marginLeft: { xs: 'calc(8px - var(--Sidebar-width))', md: isOpen ? 0 : 'calc(12px - var(--Sidebar-width))' },
                    overflow: 'hidden',
                    left: 0,
                    width: 'var(--Sidebar-width)',
                    p: 2,
                    transition: 'all 0.6s',
                    zIndex: 'var(--joy-zIndex-popup)',
                    height: '100dvh',
                    top: 0,
                    flexShrink: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRight: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <GlobalStyles
                    styles={(theme) => ({
                        ':root': {
                            '--Sidebar-width': '220px',
                            [theme.breakpoints.up('lg')]: {
                                '--Sidebar-width': '240px',
                            },
                        },
                    })}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1.5,
                        alignItems: 'center',
                    }}
                >
                    <Avatar src={'/img/icon-128x128.png'} onClick={go('/')} sx={{ cursor: 'pointer' }} size="sm" component="a" />
                    <Typography component="h1" fontWeight="xl" flexGrow={1} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '30px' }}>
                        {account?.name ?? 'Navacrew'}
                    </Typography>
                </Box>
                <Divider />
                <Box
                    sx={{
                        minHeight: 0,
                        overflow: 'hidden auto',
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        [`& .${listItemButtonClasses.root}`]: {
                            gap: 1.5,
                        },
                        minWidth: { xs: 160 },
                    }}
                >
                    {profile.type === Employee.TYPE_ADMIN && activeEvent ? (
                        <Box my={1}>
                            <OpenActiveButton
                                size="md"
                                activeEvent={activeEvent}
                                disabled={isEventOpen}
                                onClick={go(`/event/${activeEvent.id}`)}
                                fullWidth
                            >
                                <RocketLaunch />
                                Dispatch Board
                            </OpenActiveButton>
                        </Box>
                    ) : profile.type === Employee.TYPE_ADMIN && (
                        <Card
                            invertedColors
                            variant="soft"
                            color="warning"
                            size="sm"
                            sx={{ boxShadow: 'none', mb: 1 }}
                        >
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography level="title-sm">Not Deployed</Typography>
                            </Stack>
                            <Typography level="body-xs">
                                Start a new event to begin dispatching your employees.
                            </Typography>
                            <Button size="sm" variant="solid" onClick={go('/location')}>
                                Deployed Fleet
                            </Button>
                        </Card>
                    )}

                    <List
                        size="sm"
                        sx={{
                            gap: 1,
                            '--List-nestedInsetStart': '30px',
                            '--ListItem-radius': (theme) => theme.vars.radius.sm,
                        }}
                    >
                        <ListItem>
                            <ListItemButton onClick={go('/')}
                                selected={location.pathname === '/'}
                            >
                                <DashboardRoundedIcon />
                                <ListItemContent>
                                    <Typography level="title-sm">Dashboard</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>

                        <ListItem>
                            <ListItemButton
                                onClick={go('/inbox')}
                                selected={isPathActive('/inbox')}
                                disabled
                            >
                                <QuestionAnswerRoundedIcon />
                                <ListItemContent>
                                    <Typography level="title-sm" textColor="neutral.500">Messages</Typography>
                                </ListItemContent>
                                <Chip size="sm" color="neutral" variant="solid" disabled>
                                0
                                </Chip>
                            </ListItemButton>
                        </ListItem>

                        {profile.type === Employee.TYPE_ADMIN && (
                            <>
                                <ListItem>
                                    <ListItemButton onClick={go('/location')}
                                        selected={isPathActive('/location')}
                                    >
                                        <LocationIcon/>
                                        <ListItemContent>
                                            <Typography level="title-sm">Locations</Typography>
                                        </ListItemContent>
                                    </ListItemButton>
                                </ListItem>

                                <ListItem>
                                    <ListItemButton onClick={go('/employee')}
                                        selected={isPathActive('/employee')}
                                    >
                                        <GroupRoundedIcon />
                                        <ListItemContent>
                                            <Typography level="title-sm">Employees</Typography>
                                        </ListItemContent>
                                    </ListItemButton>
                                </ListItem>

                                <ListItem>
                                    <ListItemButton onClick={go('/event')}
                                        selected={isPathActive('/event')}
                                    >
                                        <DispatchIcon />
                                        <ListItemContent>
                                            <Typography level="title-sm">Events</Typography>
                                        </ListItemContent>
                                    </ListItemButton>
                                </ListItem>
                            </>
                        )}

                        <ListItem>
                            <ListItemButton onClick={go('/activity')}
                                selected={isPathActive('/activity')}
                            >
                                <ActivityIcon />
                                <ListItemContent>
                                    <Typography level="title-sm">Activity</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    </List>

                    <List
                        size="sm"
                        sx={{
                            mt: 'auto',
                            flexGrow: 0,
                            '--ListItem-radius': (theme) => theme.vars.radius.sm,
                            '--List-gap': '8px',
                            mb: 2,
                        }}
                    >
                        <ListItem>
                            <ListItemButton
                                role="menuitem"
                                component="a"
                                href="mailto:info@navacrew.com"
                            >
                                <SupportRoundedIcon />
                            Support
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton onClick={go(`/employee/${profile.id}`)}>
                                <SettingsRoundedIcon />
                            Settings
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Avatar
                        variant="outlined"
                        size="sm"
                        src={profile.picUrl}
                    />
                    <Box sx={{ minWidth: 0, flex: 1, overflow: 'hidden' }}>
                        <Typography level="title-sm" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {profile.name}
                        </Typography>
                        <Typography level="body-xs" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {profile.email}
                        </Typography>
                    </Box>
                    <IconButton size="sm" variant="plain" color="neutral" onClick={handleLogout}>
                        <LogoutRoundedIcon />
                    </IconButton>
                </Box>
            </Sheet>
        </>
    );
}
