import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Stack, Box, Typography} from "@mui/joy";

import activityActions from "actions/activityActions";
import ActivityList from "components/activity/ActivityList";
import ActivityBigAddButton from "components/activity/ActivityBigAddButton";
import {Activity} from "domain";

const UserDashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activities = useSelector(state => state.activity.list);
    const profile = useSelector(({ employees }) => employees.me);

    const handleOpen = (activity) => {
        navigate(`/dashboard/${activity.id}`);
    }

    const updateActivity = (updatedActivity) => {
        dispatch((dispatch, getState) => {
            const currentActivities = getState().activity.list;
            dispatch(activityActions.setAll(currentActivities.addUpdate(updatedActivity)));
        });
    };

    const clearActivity = (updatedActivity) => {
        dispatch((dispatch, getState) => {
            const currentActivities = getState().activity.list;
            dispatch(activityActions.setAll(currentActivities.remove(updatedActivity)));
        });
    };

    useEffect(() => {
        if (profile) {
            dispatch(activityActions.listByEmployee(profile, Activity.STATUSES_INCOMPLETE)).then(() => {
                dispatch(activityActions.watchByEmployee(profile, Activity.STATUSES_INCOMPLETE, {
                    'update': updateActivity,
                    'create': updateActivity,
                    'enter': updateActivity,
                    'leave': clearActivity,
                    'delete': clearActivity,
                }))
            });
        }
    }, [dispatch, profile]);

    useEffect(() => {
        return () => {
            dispatch(activityActions.clear());
            dispatch(activityActions.unwatchByEmployee(profile))
        }
    }, []);

    return (
        <Stack>
            <Typography level="h1" color="neutral" gutterBottom>Your Activity</Typography>
            <ActivityList activities={activities} onSelect={handleOpen} />
            <Box align="center" p={2}>
                <Typography level="body-sm" color="neutral" gutterBottom>Start a New Activity</Typography>
                <ActivityBigAddButton />
                {activities.length === 0 && (
                    <Box mt={4} px={2}>
                        <Typography level="body-sm" color="neutral" gutterBottom>
                            You have not been dispatched to any locations at this time. Keep an eye out here for any new
                            assigments, or use the above button to add an activity.
                        </Typography>
                        <Box component="img" src="/img/home-splash.svg" alt="No Activities" sx={{ maxWidth: '100%', height: 'auto', mt: 2 }} />
                    </Box>
                )}
            </Box>
        </Stack>
    );
}

UserDashboard.propTypes = {};

export default UserDashboard;
