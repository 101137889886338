import React, { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Box, Typography, Card, CardContent, Stack, Grid } from '@mui/joy';
import LocationIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import ActivityIcon from '@mui/icons-material/Newspaper';
import FleetIcon from '@mui/icons-material/People';

import activityActions from "actions/activityActions";
import LocationList from 'components/location/LocationList';
import LocationMap from 'components/location/LocationMap';
import AddLocationButton from "components/location/AddLocationButton";
import EmployeeList from "components/employee/EmployeeList";
import AddEmployeeButton from "components/employee/AddEmployeeButton";
import ActivityTable from "components/activity/ActivityTable";

function AdminDashboard() {
    const dispatch = useDispatch()
    const activities = useSelector(state => state.activity.list);

    const sections = [
        {
            title: 'Locations',
            content: (
                <Stack height="100%">
                    <LocationList />
                    <Box sx={{ flexGrow: 1, mt: 2 }} />
                    <AddLocationButton />
                </Stack>
            ),
            Icon: LocationIcon
        },
        { title: 'Map', content: <LocationMap />, Icon: MapIcon },
        { title: 'Recent Activity', content: <ActivityTable activities={activities} dense />, Icon: ActivityIcon },
        {
            title: 'Employees',
            content: (
                <Box>
                    <EmployeeList />
                    <Box sx={{ flexGrow: 1, mt: 2 }} />
                    <AddEmployeeButton />
                </Box>
            ),
            Icon: FleetIcon
        },
    ];

    useEffect(()=>{
        dispatch(activityActions.listMostRecent())
        return () => {
            dispatch(activityActions.clear())
        }
    }, [])

    return (
        <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            sx={{ flexGrow: 1 }}
        >
            {sections.map((section, index) => (
                <Grid key={index} xs={12} sm={6}>
                    <Card variant="outlined" sx={{height: '100%'}}>
                        <CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <section.Icon />
                                <Typography level="h6" component="h2">
                                    {section.title}
                                </Typography>
                            </Box>
                            <Typography component="div" height="100%">{section.content}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}

export default AdminDashboard;
