import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Stack, Box, Typography, Input, Button, Link, Container, FormControl, FormLabel } from '@mui/joy';

import authActions from 'actions/authActions';
import Notices from "components/Notices";

const Copyright = () => {
    return (
        <Typography level="body-sm" color="text.secondary" align="center" mt={4}>
            {'Copyright © '}
            <Link color="neutral" href="https://www.navacrew.com/">
                NavaCrew
            </Link>{' '}
            {new Date().getFullYear()}
            {'. All Rights Reserved.'}
        </Typography>
    );
}

const LoginForm = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(authActions.login(username, password));
    };

    return (
        <Container component="main" maxWidth="xs">
            <Notices key="notices" />
            <img src={'/img/bg.jpeg'} style={{
                position: 'absolute',
                zIndex: -1,
                width: '100vw',
                height: '100vh',
                top: 0,
                border: 0,
                right: 0,
                left: 0,
                objectFit: 'cover',
                objectPosition: 'center',
            }} />
            <Stack alignItems="center" p={2} mt={2} sx={{backgroundColor: 'background.surface', borderRadius: 'md', borderWidth: 1, borderStyle: 'solid', borderColor: 'primary.800'}}>
                <img src={'/img/logo.png'} alt="Navacrew" style={{marginTop: 16, marginBottom: 16, maxHeight: 64, maxWidth: '80%' }} />
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Stack component="form" width="100%" onSubmit={handleSubmit} noValidate spacing={2} alignItems="stretch">
                    <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input
                            color="neutral"
                            placeholder="Email Address"
                            size="lg"
                            variant="outlined"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Password</FormLabel>
                        <Input
                            color="neutral"
                            type="password"
                            placeholder="Your Password"
                            size="lg"
                            variant="outlined"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </FormControl>

                    <Button
                        type="submit"
                        fullWidth
                        variant="solid"
                        disabled={username.length === 0 || password.length === 0}
                    >
                            Sign In
                    </Button>
                    <Box align="right">
                        <Button onClick={() => navigate("/?reset")} color="primary" variant="plain">
                            Forgot Password?
                        </Button>
                    </Box>
                </Stack>
                <Copyright />
            </Stack>
        </Container>
    )
};

LoginForm.propTypes = {};

export default LoginForm;
