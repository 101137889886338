import { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";

import accountActions from 'actions/accountActions';
import locationActions from 'actions/locationActions';
import employeeActions from 'actions/employeeActions';
import authActions from 'actions/authActions';
import appActions from "actions/appActions";
import eventActions from "actions/eventActions";
import {Message} from "domain";

const Loader = () => {
    const dispatch = useDispatch();
    const isAuthorized = useSelector(({ auth }) => auth.isAuthorized);
    const user = useSelector(({ auth }) => auth.me);
    const profile = useSelector(({ employees }) => employees.me);

    const clear = () => {
        dispatch(accountActions.clear())
        dispatch(locationActions.clear())
        dispatch(employeeActions.clear())
        dispatch(eventActions.clear())
        dispatch(eventActions.unwatchActive())
    }

    const updateActiveEvent = (d) => {
        dispatch(eventActions.setActive(d))
    }

    const clearActiveEvent = () => {
        dispatch(eventActions.setActive(null))
    }

    useEffect(() => {
        if (isAuthorized) {
            dispatch(accountActions.load())
            dispatch(locationActions.list())
            dispatch(employeeActions.list())
            dispatch(employeeActions.me(user))
            dispatch(eventActions.getActive())
            dispatch(eventActions.watchActive({
                'update': updateActiveEvent,
                'create': updateActiveEvent,
                'enter': updateActiveEvent,
                'leave': clearActiveEvent,
                'delete': clearActiveEvent,
            }))
        } else {
            clear()
        }

        return () => {
            clear()
        }
    }, [isAuthorized]);

    useEffect(() => {
        return () => {
            clear()
        }
    }, []);

    useEffect(() => {
        let intervalId;

        if (profile?.id) {
            if(!profile.active) {
                dispatch(authActions.logout()).then(() => {
                    clear();
                    dispatch(appActions.addNotice(new Message({
                        title: "Inactive Account",
                        details: "Sorry, an admin has deactivated your account.",
                        type: Message.TYPE_ERROR,
                    })))
                });
                return;
            }

            const updateLastActive = () => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            const geoPoint = new Parse.GeoPoint({
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            });
                            dispatch(employeeActions.ping(geoPoint));
                        },
                        () => {
                            // Location access denied or error occurred
                            dispatch(employeeActions.ping(null));
                        },
                        { enableHighAccuracy: true } // Optional: for more accurate results
                    );
                } else {
                    // Geolocation not supported by the browser
                    dispatch(employeeActions.ping(null));
                }
            };

            updateLastActive();
            intervalId = setInterval(updateLastActive, 30000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [profile, dispatch]);

    return null;
};

export default Loader;
