import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, FormLabel, Select, Option, IconButton, Typography } from "@mui/joy";
import { selectClasses } from '@mui/joy/Select';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const TablePagination = ({
    count,
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
}) => {
    const handleChangePage = (newPage) => {
        onPageChange(newPage);
    };

    const handleChangeRowsPerPage = (event, value) => {
        onRowsPerPageChange(parseInt(value ?? 5, 10));
    };

    const getLabelDisplayedRowsTo = () => {
        return Math.min((page + 1) * rowsPerPage, count);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'flex-end' }}>
            <FormControl orientation="horizontal">
                <FormLabel>Rows per page:</FormLabel>
                <Select
                    value={rowsPerPage}
                    size="sm"
                    onChange={handleChangeRowsPerPage}
                    indicator={<KeyboardArrowDownIcon />}
                    sx={{
                        [`& .${selectClasses.indicator}`]: {
                            transition: '0.2s',
                            [`&.${selectClasses.expanded}`]: {
                                transform: 'rotate(-180deg)',
                            },
                        },
                    }}
                >
                    <Option value={5}>5</Option>
                    <Option value={10}>10</Option>
                    <Option value={25}>25</Option>
                </Select>
            </FormControl>
            <Typography textAlign="center" sx={{ minWidth: 80 }}>
                {`${page * rowsPerPage + 1}-${getLabelDisplayedRowsTo()} of ${count}`}
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton
                    size="small"
                    color="inherit"
                    disabled={page === 0}
                    onClick={() => handleChangePage(page - 1)}
                >
                    <KeyboardArrowLeftIcon />
                </IconButton>
                <IconButton
                    size="small"
                    color="inherit"
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    onClick={() => handleChangePage(page + 1)}
                >
                    <KeyboardArrowRightIcon />
                </IconButton>
            </Box>
        </Box>
    )
};

TablePagination.propTypes = {
    count: PropTypes.number.isRequired, // Total number of rows, must be a number
    page: PropTypes.number.isRequired, // Current page number, must be a number
    rowsPerPage: PropTypes.number.isRequired, // Number of rows per page, must be a number
    onPageChange: PropTypes.func.isRequired, // Function for page change, must be a function
    onRowsPerPageChange: PropTypes.func.isRequired, // Function for changing rows per page, must be a function
};

export default TablePagination;
