import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import {Box} from '@mui/joy';

const ItemLoader = ({
    children, onMore, enabled, className, component,
}) => {
    const { ref, inView } = useInView({
        threshold: 1,
        rootMargin: '50px 0px',
        delay: 100,
    });

    useEffect(() => {
        if (inView) {
            onMore();
        }
    }, [inView]);

    if (!enabled) {
        return null;
    }

    return (
        <Box ref={ref} className={className} component={component}>
            {children}
        </Box>
    );
};

ItemLoader.propTypes = {
    children: PropTypes.node.isRequired,
    onMore: PropTypes.func.isRequired,
    enabled: PropTypes.bool,
    className: PropTypes.string,
    component: PropTypes.string,
};

ItemLoader.defaultProps = {
    enabled: true,
    className: null,
    component: "div",
};

export default ItemLoader;
