import React from 'react';
import {useSelector} from "react-redux";
import {Alert, Typography} from "@mui/joy";
import WarningIcon from "@mui/icons-material/Warning";
import DangerIcon from '@mui/icons-material/Report';

import { Account } from 'domain';

const SubscriptionNotice = () => {
    const myAccount = useSelector(({ account }) => account.me);
    const employeeCount = useSelector(({ employees }) => employees.list.length);

    const billingStatus = myAccount?.billingStatus || Account.BILLING_STATUS_INACTIVE;
    const seatCount = myAccount?.seats ?? 0;
    let content = [];

    switch (billingStatus) {
        case Account.BILLING_STATUS_CANCELED:
            content.push(
                <Alert
                    color="warning"
                    key="sub-notice"
                    size="md"
                    variant="soft"
                    startDecorator={<WarningIcon />}
                    sx={{ my: 2 }}
                >
                    <div>
                        <div>Subscription Canceled</div>
                        <Typography level="body-sm" color="warning">
                            Your subscription has been canceled. Please reactivate your subscription to avoid
                            deactivation.
                        </Typography>
                    </div>
                </Alert>
            );
            break;
        case Account.BILLING_STATUS_INACTIVE:
            content.push(
                <Alert
                    color="danger"
                    key="sub-notice"
                    size="md"
                    variant="soft"
                    startDecorator={<DangerIcon />}
                    sx={{ my: 2 }}
                >
                    <div>
                        <div>Subscription Not Activated</div>
                        <Typography level="body-sm" color="danger">
                            You have not yet activated your subscription. Please subscribe in order to activate this
                            account.
                        </Typography>
                    </div>
                </Alert>
            );
            break;
        case Account.BILLING_STATUS_DEACTIVATED:
            content.push(
                <Alert
                    color="danger"
                    key="sub-notice"
                    size="md"
                    variant="soft"
                    startDecorator={<DangerIcon />}
                    sx={{ my: 2 }}
                >
                    <div>
                        <div>Subscription Canceled</div>
                        <Typography level="body-sm" color="danger">
                            Your subscription has been canceled and your account is no longer active. Please reactivate
                            your subscription to avoid deactivation.
                        </Typography>
                    </div>
                </Alert>
            );
            break;
        default:
            break;
    }


    if (seatCount === employeeCount) {
        content.push(
            <Alert
                color="warning"
                key="seat-notice"
                size="md"
                variant="soft"
                startDecorator={<WarningIcon />}
                sx={{my:2}}
            >
                <div>
                    <div>All Seats Used</div>
                    <Typography level="body-sm" color="warning">
                    You have used all your seats. Update your subscription to add more employees.
                    </Typography>
                </div>
            </Alert>
        )
    } else if (seatCount < employeeCount) {
        content.push(
            <Alert
                color="danger"
                size="md"
                variant="soft"
                startDecorator={<DangerIcon />}
                sx={{my:2}}
            >
                <div>
                    <div>Insufficient Seats</div>
                    <Typography level="body-sm" color="danger">
                        Please subscribe to more seats or remove employees from this account.
                    </Typography>
                </div>
            </Alert>
        )
    }

    return <>{content}</>;
}

SubscriptionNotice.propTypes = {};

export default SubscriptionNotice;
