import React from 'react';
import { DateTime } from 'luxon';
import { Chip } from '@mui/joy';
import PropTypes from "prop-types";

const TimeStatus = ({ date }) => {
    const getRelativeTime = (date) => {
        if (!date) return { label: 'N/A', color: 'default' };

        let dateTime;
        if (date.iso) {
            dateTime = DateTime.fromISO(date.iso);
        } else if (date instanceof Date) {
            dateTime = DateTime.fromJSDate(date);
        } else if (typeof date === 'string') {
            dateTime = DateTime.fromISO(date);
        } else {
            return { label: 'Invalid date', color: 'default' };
        }

        const now = DateTime.now();
        const diffInMinutes = now.diff(dateTime, 'minutes').minutes;
        const diffInDays = now.diff(dateTime, 'days').days;

        if (diffInMinutes < 1) {
            return { label: 'Now', color: 'success' };
        } else if (diffInDays < 1) {
            return { label: dateTime.toRelative(), color: 'warning' };
        } else {
            return { label: dateTime.toRelative(), color: 'danger' };
        }
    };

    const { label, color } = getRelativeTime(date);

    return (
        <Chip color={color} variant="soft" size="sm" sx={{"--Chip-paddingInline": "12px"}}>
            {label}
        </Chip>
    );
};

TimeStatus.propTypes = {
    date: PropTypes.any,
}

export default TimeStatus;
