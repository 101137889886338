import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import {useNavigate} from "react-router-dom";
import {Avatar, Box, Button, Input, Stack, Typography} from "@mui/joy";
import EmailIcon from "@mui/icons-material/Email";

// Replace with actual import
import OverlayModal from "components/OverlayModal";
import authActions from "actions/authActions";
import appActions from "actions/appActions";
import {Message} from "domain";

const ForgotPasswordForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const handleChange = (evt, val) => {
        setEmail(val ?? evt.target.value)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        dispatch(authActions.reset(email)).then(() => {
            dispatch(appActions.addNotice(new Message({
                title: 'Reset Link Sent',
                details: 'Please check your email, and use the provided link to reset your password.',
                type: Message.TYPE_SUCCESS
            }))).then(() => {
                setEmail('');
                navigate('/classroom');
            });
        })
    };

    return (
        <OverlayModal>
            <Avatar sx={{ mt: 2 }} color="primary">
                <EmailIcon />
            </Avatar>
            <Typography level="title-lg">Forgot Password</Typography>
            <Typography level="body-sm" textAlign="left" my={1}>
                Enter your email address and we will send you a link to reset your password.
            </Typography>

            <Stack component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
                <Box mt={2} width="100%">
                    <Input
                        type="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={handleChange}
                    />
                </Box>
                <Button
                    type="submit"
                    fullWidth
                    variant="solid"
                    onClick={handleSubmit}
                    disabled={!email}
                >
                        Send Reset Link
                </Button>

                <Box align="right" mt={2}>
                    <Button onClick={() => navigate("/")} color="primary" variant="plain">
                        Cancel
                    </Button>
                </Box>
            </Stack>
        </OverlayModal>
    );
}

export default ForgotPasswordForm;
