import {Employee} from 'domain';
const Parse = global.Parse;

const EMPLOYEE_FIELDS = Object.keys(Employee.DEFAULTS);

const get = (employeeId) => ({
    type: 'GET_EMPLOYEE',
    meta: { employeeId },
    payload: new Parse.Query(Employee)
        .select(EMPLOYEE_FIELDS)
        .get(employeeId),
});

const set = (employee) => ({
    type: 'SET_EMPLOYEE',
    meta: { employee },
    payload: Promise.resolve(employee),

});

const list = () => ({
    type: 'LIST_EMPLOYEE',
    meta: {},
    payload: new Parse.Query(Employee)
        .equalTo('active', true)
        .descending(['lastActive', 'name'])
        .select(EMPLOYEE_FIELDS)
        .limit(1000)
        .find(),
});

const save = (employee) => ({
    type: 'SAVE_EMPLOYEE',
    meta: { employee },
    payload: employee.save(),
});

const add = (employee) => ({
    type: 'ADD_EMPLOYEE',
    meta: { employee },
    payload: Parse.Cloud.run('addEmployee', { employee: employee.toJSON() }),

});

const findEmployeeActivePeriods = (employee) => ({
    type: 'FIND_EMPLOYEE_ACTIVE_PERIODS',
    meta: { employee },
    payload: Parse.Cloud.run('findEmployeeActivePeriods', { employee: employee.toJSON() }),
});

const findEmployeeLocations = (employee, from, to, isGrouped = true) => ({
    type: 'FIND_EMPLOYEE_LOCATIONS',
    meta: { employee, from, to },
    payload: Parse.Cloud.run('findEmployeeLocations', { employee: employee.toJSON(), from, to, isGrouped }),

});

const clear = () => ({
    type: `CLEAR_EMPLOYEE`,
    meta: {},
    payload: Promise.resolve(null)
});

/**
 * Fetches the current authenticated user's Profile.
 *
 * @returns {Object|null} - The current user's details or null if not logged in.
 */
const ping = (geo) => ({
    type: 'PING_EMPLOYEE',
    meta: { geo },
    payload: Parse.Cloud.run('ping', { geo }),
});

/**
 * Reset's the employee password to a randomly generated password that will be emailed to them.
 *
 * @returns {Object|null} - The current user's details or null if not logged in.
 */
const resetPassword = (employee) => ({
    type: 'RESET_PASSWORD_EMPLOYEE',
    meta: { employee },
    payload: Parse.Cloud.run('resetPassword', { employee: employee.toJSON() }),
});

/**
 * Fetches the current authenticated user's Profile.
 *
 * @returns {Object|null} - The current user's details or null if not logged in.
 */
const me = (user) => ({
    type: 'RESTORE_EMPLOYEE',
    meta: { user },
    payload: new Parse.Query(Employee)
        .equalTo('user', user.toPointer())
        .select(EMPLOYEE_FIELDS)
        .first(),
});

export default {
    get,
    set,
    list,
    save,
    add,
    clear,
    ping,
    resetPassword,
    findEmployeeActivePeriods,
    findEmployeeLocations,
    me,
};
