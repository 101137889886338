import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ForgotPasswordForm from "components/ForgotPasswordForm";
import LoginForm from "components/LoginForm";

import authActions from 'actions/authActions';

const Authorize = ({ children }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isReset = queryParams.get('reset') !== null;
    const isAuthorized = useSelector(({ auth }) => auth.isAuthorized);

    useEffect(() => {
        dispatch(authActions.me());
    }, []);

    if (!isAuthorized) {
        return isReset ? <ForgotPasswordForm /> : <LoginForm />
    }

    return children;
}

Authorize.propTypes = {
    children: PropTypes.node,
};

export default Authorize;
