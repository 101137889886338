import React from 'react';
import PropTypes from "prop-types";
import { Box } from '@mui/joy';

import Loader from './Loader';
import Notices from './Notices';
import Sidebar from './Sidebar';
import Breadcrumb from "components/Breadcrumb";

const Layout = ({ children }) => {
    return (
        <Box display="flex" flexDirection="row" gap={0}>
            <Loader/>
            <Sidebar/>
            <Box flexGrow={1}>
                <Breadcrumb />
                <Box p={2} component="main">
                    {children}
                </Box>
            </Box>
            <Notices key="notices"/>
        </Box>
    )
};

Layout.propTypes = {
    children: PropTypes.node,
};

export default Layout;
